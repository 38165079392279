




































































































































































































































































































































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { getAvatarUserData, getImageByPerson } from '../../../interfaces/service/person/userAuthority'
import { getTypeDeviceData } from '../../../interfaces/service/device/deviceHardWare'
import { getRecognizeHistogram } from '../../../interfaces/service/recognize/statusChart'
import { getRecognizePersonsInfo } from '../../../interfaces/service/recognize/rententPerson'
import { apiUrl } from '../../../env'
import searchTool from '../other/SearchToolBar.vue'
import VeLine from 'v-charts/lib/line.common'
import drawerUser from '../user/UserAddModifyDraw.vue'
import drawerDevice from '../device/DeviceHomeDraw.vue'
import newDeviceModel from './NewDeviceModel.vue'
import DeviceCretificStatus from './home_status/DeviceCretificStatus.vue'
import svgDeviceBindUser from '../../../components/svg_saves/home_page_show/user_list_home/DeviceBindUser.vue'
import svgDeviceGreen from '../../../components/svg_saves/home_page_show/user_list_home/DeviceGreen.vue'
import svgDeviceBlack from '../../../components/svg_saves/home_page_show/user_list_home/DeviceBlack.vue'
import svgFilter from '../../../components/svg_saves/main/Filter.vue'
import _ from 'lodash'
import { getNow, getDateByDateRange } from '../../../utils/util'
import moment from 'moment'
import { getBase64 } from '../../../interfaces/service/person/personMang'
import { readRanterId } from '@/store/main/getters'
@Component({
  components: {
    svgDeviceBindUser,
    svgDeviceGreen,
    svgDeviceBlack,
    svgFilter,
    searchTool,
    VeLine,
    drawerUser,
    drawerDevice,
    newDeviceModel,
    DeviceCretificStatus,
  },
  data() {
    return {
      svgDeviceBindUser,
      svgDeviceGreen,
      svgDeviceBlack,
      svgFilter,
      apiUrl,
    }
  },
  filters: {
    diveceStatus(statusCode) {
    if (statusCode === 0) { return 'Online' }
    return 'Offline'
    }
  }
})
export default class DeviceHomeList extends Vue {
  get deviceCounter(): number {
    // 设备数
    return this.deviceListData ? this.deviceListData.length : 0
  }

  get deviceCertificInfoLeftClass(): string {
    const prefix: string = 'device-certific-info__left'
    return -1 !== this.ranterChose ? `${prefix}--choose` : prefix
  }

  get deviceCertificInfoRightClass(): string {
    const prefix: string = 'device-certific-info__right'
    return -1 !== this.ranterChose ? `${prefix}--choose` : prefix
  }
  get deviceListChunk() {
    // 将deviceList分为两栏
    let partLength: number
    let deviceListPart
    const deviceList = this.deviceListDataFiter || this.deviceListData
    if (deviceList) {
      partLength = Math.ceil(deviceList.length / 2)
    } else {
      partLength = 0
    }

    deviceListPart = _.chunk(deviceList, partLength)
    const deviceListoff: any = []

    if (deviceListPart[1]) {
      deviceListoff.push(deviceListPart[0], deviceListPart[1], deviceList)
    } else {
      deviceListoff.push(deviceListPart[0], [], deviceList)
    }
    return deviceListoff
  }

  /**
   * 当前时间
   */
  get currentTime(): string {
    return moment().format('HH:mm')
  }

  public theWindowH = document.body.clientHeight
  public loading: boolean = true
  @Prop({ required: true }) public ranterChose!: number
  @Prop({ required: true }) public theUserDatas!: any
  @Prop({ required: true }) public choseORG!: any // 选择的子org
  @Prop({ required: true }) public orgCounter!: number // 子组织数
  @Prop({ required: true }) public orgId!: number // 当前orgId
  public userDatas: any = []
  public recentUserDatas: any = []
  public deviceListData: any = []
  public deviceListDataFiter: any = null
  public theOrgID = sessionStorage.getItem('ranterId') // org_id chose
  public userAddModifyDrawFlag: boolean = false // draw show flag
  public collTapChangeKey: number = -10 // collaspan tap key
  public collTapChangeKeyPart2: number = -10 // collaspan tap key
  // search tool bar action 搜索反馈方法
  public deviceListShowFlag: boolean = true
  // search tool bar action 搜索反馈方法 END
  // search device function 搜索设备 EDN
  public userDetaildata: any = null
  public deviceDetail: any = null
  public deviceDetailDrawFlag: boolean = false
  // 新建设备·绑定已有的设备
  public deviceNewDrawFlag: boolean = false
  public bindDeviceModelFlag: boolean = false
  public cardViewSelectedId: string = ''
  public dateRange: moment.unitOfTime.StartOf = 'day'
  public chartData: any = {
          columns: [],
          rows: []
        }
  public frequency: number = 0 // 当前时间下认证人数 （人次）
  public get legend() : any {
    return { bottom: '-20px' }
  }
  public get chartSettings() : any {
    return {
      yAxis: {
        axisLabel: {show: false},
        splitLine: {show: false},
        type : 'value',
      },
      series: {
        type: 'line',
        smooth: false
      }
    }
  }
  public get ranterId() : number {
    let id: number = readRanterId(this.$store)
    if (id === -10) { id = +(sessionStorage.getItem('ranterId') || -10) }
    return id
  }

  @Watch('$store.state.main.screen.height') public windowHeight() {
    this.theWindowH = document.body.clientHeight
  }
  @Watch('ranterId') public async getUserAvatar() {
    // 重置折叠面板选择index
    this.collTapChangeKey = -10
    // 将orgId 设为租户Id
    if (this.ranterId === -10) { return }
    this.handleDeviceRequests(this.ranterId)
  }
  @Watch('orgId')
  public updateOrgId() {
    // 重置折叠面板选择index
    this.collTapChangeKey = -10
    if (this.orgId === -10) { return }
    this.handleDeviceRequests(this.orgId)
  }
  @Watch('dateRange')
  public async updateDateRange() {
    // 获取 chart 数据
    const chartData = await this.getChartData(this.orgId)
    this.buildChartData(chartData)
    // 最近认证人员信息

    if (this.orgId === -10) { return }
    this.recentUserDatas =  await this.getRecentRecognizesUserData(this.orgId)
    this.getFinalAvatar(this.orgId, this.recentUserDatas)
  }

  public newDeviceSubmit(returnData) {
    // 新建设备
    this.deviceNewDrawFlag = returnData.colseFlag
    if (returnData.deviceDataBind) {
      this.deviceListData = returnData.deviceDataBind
    }
  }
  public async bindModelSubmit(returnData) {
    this.deviceListDataFiter = null
    // 绑定已有的设备
    this.bindDeviceModelFlag = returnData.visable
    await getTypeDeviceData(
      this.$store.state.main.userProfile.user_type,
      this.orgId
    )
    await this.$nextTick(() => {
      this.deviceListData = this.$store.state.main.deviceHardWare.deviceData
    })
  }
  public collTapChange(e) {
    const scrollEle: NodeListOf<HTMLElement> = document.querySelectorAll(
      '.conapseDiv'
    ) as NodeListOf<HTMLElement>
    let i = 0
    scrollEle[0] ? scrollEle[0].scrollTop = 46 * (e || this.collTapChangeKey) : i = 1
    this.collTapChangeKey = e
  }
  public collTapChangePart2(e) {
    const scrollEle: NodeListOf<HTMLElement> = document.querySelectorAll(
      '.conapseDiv'
    ) as NodeListOf<HTMLElement>
    let i = 0
    scrollEle[0] ? scrollEle[0].scrollTop = 46 * (e || this.collTapChangeKeyPart2) : i = 1
    this.collTapChangeKeyPart2 = e
  }
  public searchAction(searchData) {
    if (searchData) {
      switch (searchData.searchType) {
        case 'DeviceMang':
          this.deviceFilterSearch(searchData.searchValue)
          this.deviceListShowFlag = searchData.lineShowFlag
          break
      }
    }
    if (searchData.plusFlag) {
      this.bindDeviceModelFlag = !this.bindDeviceModelFlag
    }
    if (searchData.addNew) {
      this.deviceNewDrawFlag = !this.deviceNewDrawFlag
    }
  }

  public drawOnUser(userdata) {
    this.userDetaildata = userdata
    this.userAddModifyDrawFlag = true
  }
  public drawOnDevice(deviceData) {
    this.deviceDetail = deviceData
    this.deviceDetailDrawFlag = true
  }
  public async userDetailClose() {
    this.userAddModifyDrawFlag = false
    // 重新获取用户数据
    if (this.orgId === -10) { return }
    this.recentUserDatas = await this.getRecentRecognizesUserData(this.orgId)
    this.getFinalAvatar(this.orgId, this.recentUserDatas)
  }
  public async deviceDetailAction(returnData) {
    this.deviceDetailDrawFlag = false
    if (returnData.deviceDataBind) {
      await getTypeDeviceData(this.$store.state.main.userProfile.user_type, this.orgId)
      await this.$nextTick(() => {
        this.deviceListData = this.$store.state.main.deviceHardWare.deviceData
      })
    }
  }

  /**
   * 时间格式化
   * @param {Date} time 认证时间
   * @return {String} hh:mm 形式的时间字符串
   */
  public timeShowFormat(time) {
    return moment(time, 'yyyy.MM.dd HH:mm').format('HH:mm')
  }

  /**
   * 处理设备认证信息显示区域 日期选择下拉框 change 事件
   * @param {String} value 当前选中的值
   */
  public handleDateChange(value) {
    this.dateRange = value
  }

  /**
   * 将Card 视图中的设备名称前的icon设为传入的icon
   */
  public setCardViewSelectedId(selectedId) {
    this.cardViewSelectedId = selectedId
  }

  /**
   * 获取折线图数据
   */
  private async getChartData(id) {
    const config = {
      end_time: getNow(),
      start_time: getDateByDateRange(this.dateRange)
    }
    return getRecognizeHistogram(id, config)
  }

  /**
   * 构造折线图所需数据格式
   */
  private buildChartData(originData: any) {
    const textRecognizeNum = this.$i18n.tc('device.home.recognize_count')
    const rows: any[] = []

    // 保存当前时间下认证人数
    this.frequency = originData[0].recognize_num

    if (this.dateRange === 'day') {
      // 按日筛选，横坐标以小时为单位
      this.chartData.columns = ['hour', textRecognizeNum]
      originData.forEach((item, index) => {
        const obj: any = {}
        // 格式化小时 eg: 6 -> 06 | 12 -> 12
        const formatedHour = index.toString().length === 1 ? `0${ index }` : index
        obj['hour'] = `${ formatedHour }:00`

        obj[textRecognizeNum] = item.recognize_num
        rows.push(obj)
      })
    } else {
      // 按周或月进行筛选，横坐标以天为单位
      this.chartData.columns = ['day', textRecognizeNum]
      const reverseData = originData.reverse()

      // 将小时数据组按天分类
      const days = _.chunk(reverseData, 24)

      days.forEach((item: any, index: number) => {
        const obj: any = {}
        // 获取开始时间 MM-DD 形式
        const startOfDay: string = getDateByDateRange(this.dateRange, 'MM-DD')
        obj['day'] = moment(startOfDay, 'MM-DD').add(index, 'days').format('MM-DD')

        // 以天为单位计算认证人数
        const recognizeNum = item.reduce((prev: number, current: any) => {
          return prev + current['recognize_num']
        }, 0)

        obj[textRecognizeNum] = recognizeNum
        rows.push(obj)
      })
    }

    this.chartData.rows = rows
  }

/**
 * 获取最近认证人员信息
 * @param {number} id orgId | ranterId
 */
  private async getRecentRecognizesUserData(id) {
    if (id === -10) { return }
    return getRecognizePersonsInfo(id, { end_time: getNow(), start_time: getDateByDateRange(this.dateRange) })
  }

  /**
   * 获取真实图片信息，并重新封装对象
   * @param {number} id 请求Id
   * @param {any[]} data 后台请求得到的用户数据
   */
  private getFinalAvatar(id, data) {
    if (data.length === 0) {
      return []
    }

    _.forIn(data, async (item) => {
      if (_.isEmpty(item.person_faces)) {
        return
      }
      await getImageByPerson(id, item.person_faces[0].image_url)

      const url = await getBase64(this.$store.state.main.messagePro)
      this.$set(item, 'avatarUrl', url)
    })
  }

  // search tool bar action 搜索反馈方法
  // search device function 搜索设备
  private deviceFilterSearch(fliterValue) {
    if (fliterValue) {
      const searchListData = this.deviceListData
        .map((item: any) => {
          if (item.name.indexOf(fliterValue) > -1) {
            return item
          }
          return null
        })
        .filter((item, i, self) => item && self.indexOf(item) === i)
      this.deviceListDataFiter = searchListData
    } else {
      this.deviceListDataFiter = null
    }
  }

  /**
   * 设备页面相关请求流程处理
   */
  private async handleDeviceRequests(id) {
    this.loading = true
    await getAvatarUserData(this.$store.state.main.userProfile.user_type, id)
    await getTypeDeviceData(this.$store.state.main.userProfile.user_type, id)

    const chartData = await this.getChartData(id)
    this.buildChartData(chartData)
    // 获取最近认证人员信息
    this.recentUserDatas =  await this.getRecentRecognizesUserData(id)
    this.getFinalAvatar(id, this.recentUserDatas)

    this.loading = false
    this.userDatas = this.$store.state.main.userAuthorityShow.userData
    this.deviceListData = this.$store.state.main.deviceHardWare.deviceData

    this.$emit('onChange', {size: this.deviceListData.length})
  }

  /**
   * create 生命周期函数
   */
  private async created() {
    const id = this.orgId

    if (id === -10) { return }
    await getAvatarUserData(this.$store.state.main.userProfile.user_type, id)
    await getTypeDeviceData(this.$store.state.main.userProfile.user_type, id)
    const chartData = await this.getChartData(id)
    this.buildChartData(chartData)

    this.recentUserDatas =  await this.getRecentRecognizesUserData(id)
    this.getFinalAvatar(id, this.recentUserDatas)

    this.userDatas = this.$store.state.main.userAuthorityShow.userData
    this.deviceListData = this.$store.state.main.deviceHardWare.deviceData
    this.$emit('onChange', {size: this.deviceListData.length})
    this.loading = false
  }
}
