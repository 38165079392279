































import { Vue, Prop, Component, Watch } from 'vue-property-decorator'
import moment from 'moment'
import StatusItem from './StatusItem.vue'
import { getRecognizeStatisticInfo } from '@/interfaces/service/recognize/statusRecognize'
import { getNow, getDateByDateRange} from '@/utils/util'
import { readRanterId } from '@/store/main/getters'


@Component(
  {components: { StatusItem }}
)
export default class DeviceCretificStatus extends Vue {

  /**
   * 租户ID
   */
  public get ranterId() : number {
    let id: number = readRanterId(this.$store)
    if (id === -10) { id = +(sessionStorage.getItem('ranterId') || -10) }
    return id
  }

  @Prop({ required: true, default: 'today' }) public dateRange!: moment.unitOfTime.StartOf // 时间跨度 today | week
  @Prop({ required: true, default: -10 }) public orgId!: number // 当前orgId

  public data: any = {
                      certified_num: 0,
                      female_num: 0,
                      male_num: 0,
                      recognize_num: 0,
                      temp_abnormal_num: 0,
                      temp_normal_num: 0,
                      total_num: 0,
                      uncertified_num: 0
                    }

  /**
   * 租户切换时
   */
  @Watch('ranterId')
  public updateRanterId() {
    this.searchStatus(this.ranterId)
  }

  /**
   * 组织切换时
   */
  @Watch('orgId')
  public updateOrgId() {
    this.searchStatus(this.orgId)
  }

  /**
   * 筛选框改变时
   */
  @Watch('dateRange')
  public updateDateRange() {
    this.searchStatus(this.orgId)
  }

  private async created() {
    await this.searchStatus(this.orgId)
  }

  /**
   * 获取认证状态信息
   * @param {number} id orgId | ranterId
   */
  private async searchStatus(id) {
    const config = {
      end_time: getNow(),
      start_time: getDateByDateRange(this.dateRange)
    }
    if (id === -10) { return }
    const res =  await getRecognizeStatisticInfo(id, config)
    this.data = res
  }
}
