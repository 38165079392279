













































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import _ from 'lodash'
import { getAvatarUserData, getImageByPerson } from '../../../interfaces/service/person/userAuthority'
import { apiUrl } from '../../../env'
import svgDeviceBindUser from '../../../components/svg_saves/home_page_show/user_list_home/DeviceBindUser.vue'
import svgIdCardOutline from '../../../components/svg_saves/main/IdCardOutline.vue'
import svgIdCardActive from '../../../components/svg_saves/main/IdCardActive.vue'
import searchTool from '../other/SearchToolBar.vue'
import drawerUser from './UserAddModifyDraw.vue'
import newUserModel from '../user/NewUserModel.vue'
import { getBase64 } from '../../../interfaces/service/person/personMang'
@Component({
  components: {
    svgDeviceBindUser,
    svgIdCardOutline,
    svgIdCardActive,
    searchTool,
    drawerUser,
    newUserModel,
  },
  data() {
    return {
      svgDeviceBindUser,
      svgIdCardOutline,
      svgIdCardActive,
      newUserModel,
      apiUrl,
    }
  },
})
export default class UserListHome extends Vue {
  @Prop({ required: true }) public choseORG!: any // 选中的子部门
  @Prop({ required: true }) public orgCounter!: any // 子组织数
  @Prop({ required: true }) public deviceCounter!: number // 部门绑定设备数
  @Prop({ required: true }) public orgId!: number // 当前 orgId
  get userCounter() {
    return this.userDataInGroup ? this.userDataInGroup.length : 0
  } // 部门人数
  public userLineShowFlag: boolean = true
  public userDataInGroup: any = []
  public userDataAllInGroup : any = [] // 搜索显示时，存储全部部门拥有的人员;
  public userAddModifyDrawFlag: boolean = false // 用互详情显示
  public userdetailData: any = null
  public deviceData: any = null // 可绑定的设备 all device
  // Draw function 抽屉页面方法 END
  public collTapChangeKey: number = -10 // 反馈点击的条目的key值 , key value of person list touched
  public loading: boolean = true
  // 添加新的用户
  public newUserModelFlag: boolean = false
  public newProfileFlag: boolean = false
  // function to search
  @Watch('orgId') public async getUserAvatar() {
    this.loading = true
    await getAvatarUserData(
      this.$store.state.main.userProfile.user_type,
      this.orgId
    )
    // 重置折叠面板选中index
    this.collTapChangeKey = -10
    this.userDataInGroup = this.$store.state.main.userAuthorityShow.userData
    this.$emit('onChange', {size: this.userDataInGroup.length})
    this.getFinalAvatar(this.userDataInGroup)
    this.userDataAllInGroup = this.$store.state.main.userAuthorityShow.userData
    this.loading = false
  }
  public searchAction(searchData) {
    this.userLineShowFlag = searchData.lineShowFlag
    if (searchData.searchType === 'userListIngroup') {
      this.userDataSearch(searchData.searchValue)
    }
    if (searchData.plusFlag) {
      // 已有人员绑定ORG
      this.newUserModelFlag = true
    }
    if (searchData.addNew) {
      // 添加新的人员信息
      this.newProfileFlag = true
    }
  }
  public async newModelSubmit(returnData) {
    this.newUserModelFlag = returnData.colseFlag
    await getAvatarUserData(this.$store.state.main.userProfile.user_type, this.orgId)
    await this.$nextTick(() => {
      this.userDataInGroup = this.$store.state.main.userAuthorityShow.userData
      this.getFinalAvatar(this.userDataInGroup)
      this.userDataAllInGroup = this.$store.state.main.userAuthorityShow.userData
    })
  }
  public async userChangeSubmit(returnData) {
    this.newProfileFlag = false
    await getAvatarUserData(this.$store.state.main.userProfile.user_type, this.orgId)
    await this.$nextTick(() => {
      this.userDataInGroup = this.$store.state.main.userAuthorityShow.userData
      this.getFinalAvatar(this.userDataInGroup)
      this.userDataAllInGroup = this.$store.state.main.userAuthorityShow.userData
    })
  }
  // search user Data action,搜索用户方法触发
  public userDataSearch(e : string) {
    const userValue = e
    if (e.length > 0) {
       const searchListData = this.userDataInGroup
      .map((item: any) => {
        if (item.name.indexOf(userValue) > -1) {
          return item
        }
        return null
      })
      .filter((item, i, self) => item && self.indexOf(item) === i)
       this.userDataInGroup = searchListData
    } else {
       this.userDataInGroup = this.userDataAllInGroup
    }
  }
  // Draw function 抽屉页面方法

  public userTap(e) {
    this.userAddModifyDrawFlag = true
    this.userdetailData = e
  }
  // userData collapse
  public collTapChange(e) {
    this.collTapChangeKey = e
  }

  public userDetailClose() {
    this.userAddModifyDrawFlag = false
  }

  public buildBreadcrumbData(orgIds: any, queue: any[]) {
    const enterpriseHashData = this.$store.state.main.enterprise.enterpriseHashData
    const { id, name } = orgIds

    if (_.has(enterpriseHashData[id], 'parent_id') && enterpriseHashData[id].parent_id === 0) {
      return queue
    }

    if (_.has(enterpriseHashData[id], 'parent_id')) {
      queue.unshift(name)
      this.buildBreadcrumbData(enterpriseHashData[enterpriseHashData[id].parent_id], queue)
    } else {
      queue.unshift(name)
    }

    return queue
  }

  /**
   * 获取真实图片信息，并重新封装对象
   * @param {any[]} data 后台请求得到的用户数据
   */
  public getFinalAvatar(data) {
    if (data.length === 0) {
      return []
    }

    _.forIn(data, async (item) => {
      if (_.isEmpty(item.person_faces)) {
        return
      }
      await getImageByPerson(this.orgId, item.person_faces[0].image_url)

      const url = await getBase64(this.$store.state.main.messagePro)
      this.$set(item, 'avatarUrl', url)
    })
  }

  private async created() {
    await getAvatarUserData(
      this.$store.state.main.userProfile.user_type,
      this.orgId
    )
    this.userDataInGroup = this.$store.state.main.userAuthorityShow.userData
    this.$emit('onChange', {size: this.userDataInGroup.length})

    // 获取有真实照片的用户数据
    this.getFinalAvatar(this.userDataInGroup)

    this.userDataAllInGroup = this.$store.state.main.userAuthorityShow.userData
    this.loading = !this.loading
  }
  // VUE 生命周期 END
}
