































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import _ from 'lodash'
import {
  multiBindPersonDevices,
  creatNewPerson,
  personInfoUpdate,
  getDeviceData_BindPerson,
  getImageByPerson,
  multDeletePersonFace,
  getOnePersonDetail,
  personAvatar,
  getAvatarUserData,
  addPersonFaces
} from '../../../interfaces/service/person/userAuthority'
import {
  getTypeDeviceData,
  getDeviceBindInfo,
} from '../../../interfaces/service/device/deviceHardWare'
import { getBase64 } from '../../../interfaces/service/person/personMang'
import searchTool from '../other/SearchToolBar.vue'
import { commitSetLoading } from '../../../store/main/mutations'
import * as imageConversion from 'image-conversion'
const pageReturnDatas: any = {}
@Component({
  components: {
    searchTool,
  },
  data() {
    return {
    }
  }
})
export default class UserAddModifyDraw extends Vue {
  // 人名读取判断，邮箱判断 ，添加orgIDS判断，userList中user的刷新（使用新的后台接口1.userList，2.deviceList）
  @Prop({ required: true }) public userCounter!: number // 部门人数
  @Prop({ required: true }) public orgCounter!: number // 子组织数
  @Prop({ required: true }) public deviceCounter!: number // 部门绑定设备数
  @Prop({ required: true }) public orgData!: any // 当前部门
  @Prop({ required: true }) public newFlag!: boolean // 画面区分(True:完全新规、False:更新)
  @Prop() public userdetailDataID!: any // 用户详情
  public userFormData: any = {} // 提交用户信息表单数据 formModel
  public theWindowH = document.body.clientHeight
  public deviceBinds: any = []
  public theRanterIDs = sessionStorage.getItem('ranterId') // orgID chose选中的orgid
  public deviceDataCanBind: any = [] // 可绑定的设备 all device
  public initORGFlag: boolean = true // 是否是初始化状态，初始化时，不可选择默认的org
  // 头像上传
  public previewVisible: boolean = false
  public previewImage: string = ''
  public uploading: boolean = false
  public fileList: any = [] // 头像文件数组
  public avatarFile: any[] = [] // 上传头像后用于绑定user
  // 头像上传END

  // org自动提示输入
  public dataList: any = []
  public valORgStatus: string = '' // 选择部门的报错信息
  public valORgHelp: string = '' // 选择部门的报错信息
  public userFromOrgMult: any = []// 绑定选择的部门name
  public orgChangeStore: any = {}// orgChange初始比对值(推算变化的org从而改变绑定的设备)
  // org自动提示输入 END

  // 姓名输入检查
  public valNameHelp: string = ''
  public valNameStatus: string = ''
  // 页面数据提交
  public valEmailStatus: string = ''
  public valEmailHelp: string = ''
  public valTelStatus: string = ''
  public valTelHelp: string = ''
  public fileStatus: boolean = false
  public subLoading: boolean = false
  public deviceHandbinds : string[] = [] // 手动绑定添加用于disabled验证
  public defaultAvatarHave : number = 0 // 存在默认的头像(默认0张头像)
  public deleteDefaultAvatar : any = [] // 删除已有的头像
  public readonlyName: string = ''
  @Watch('$store.state.main.screen.height')
  public windowHeight() {
    this.theWindowH = document.body.clientHeight
  }
  @Watch('$store.state.main.waitloading')
  public resetLoading() {
    this.subLoading = this.$store.state.main.waitloading
  }
  public orgSelectFun() {
    this.initORGFlag = false // 结束页面的初始化
  }
  public orgDisableFun(orgName) {
    if (this.initORGFlag) {
      // 初始化时
      if (_.indexOf(this.userFromOrgMult, orgName) > -1) {
        // 选中org与默认的相同
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }
  // 设备绑定
  public transChange(targetKeys, direction, moveKeys) {
    if (direction === 'right') {
      this.deviceHandbinds = _.union(this.deviceHandbinds, moveKeys)// 手动绑定添加用于disabled验证
    } else {
      _.pullAll(this.deviceHandbinds, moveKeys)
    }
    this.deviceBinds = targetKeys
  }
  public async avatarPreview(file) {
    // 头像预览
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    this.previewImage = file.url || file.preview
    this.previewVisible = true
  }
  public async avatarChange(fileList) {
    // 只有新加图片并且检证OK的时候才上传
    if (this.fileStatus) {
      this.fileStatus = false
      // console.log(fileList.file)
      const fname = fileList.file.name
      const ftype = fileList.file.type
      // 大于1MB 压缩800k
      if (fileList.file.size > 1024000) {
        const res = await imageConversion.compressAccurately(fileList.file, 800)
        fileList.file = new File([res], fname, {type: ftype, lastModified: Date.now()})
      }
      // console.log(fileList.file)
      // 每次点击上传头像时，传入后台，取得后台的Uid与序号（序号为数组序号）
      await personAvatar(fileList.file, this.fileList.length + 1, this.theRanterIDs)
      this.avatarFile.push(this.$store.state.main.messagePro)
      this.fileList = fileList.fileList
    }
  }
  public beforeUpload(file) {
    // 文件check
    this.imgUpLoadCheck(file)
    return false
  }
  public imgUpLoadCheck(file) {
    if (file.type === 'image/png' || file.type === 'image/jpeg') {
      // if (file.size > 2048000) {
      //   // 大于2MB
      //   this.$message.error(this.$i18n.tc('user.add_modify_draw.error_more_than_2m'))
      //   this.fileStatus = false
      //   return
      // }
    } else {
      // 非一般图片格式
      this.$message.error(this.$i18n.tc('user.add_modify_draw.error_jpg_only'))
      this.fileStatus = false
      return
    }
    this.fileStatus = true
  }
  // 删掉上传的图片
  public handleRemove(file) {
    // 更新时1张不能删除
    if (this.newFlag && this.fileList.length <= 1) {
      this.$message.error(this.$i18n.tc('user.add_modify_draw.error_image'))
      return
    }
    const index = this.fileList.indexOf(file)
    const newFileList = this.fileList.slice()
    // 删除图片的情况针对index删除方法：
    // 1.personFaceId存在的时候删除既存
    //   (既存图片一定在最前方)
    // 2.personFaceId不存在的时候
    //   (一定是新规)
    if (this.fileList[index]) {
      //  删除已经绑定的图
      this.deleteDefaultAvatar.push(this.fileList[index].personFaceId)
      this.defaultAvatarHave = this.defaultAvatarHave - 1
    } else {
      //  删除新规
      this.avatarFile.splice(index - this.defaultAvatarHave, 1)
    }
    // （删除时的位置，删除张数）位置从index=0开始计算
    newFileList.splice(index, 1) // 删除fileList中用于显示的头像
    this.fileList = newFileList
  }
  public imageInputCheck(): boolean {
    if (this.fileList.length < 1) {
      this.$message.error(this.$i18n.tc('user.add_modify_draw.error_image'))
      return false
    }
    return true
  }
  public avatarCancel() {
    // 关闭预览
    this.previewVisible = false
  }
  public generateList(data) {
    for (const i of data) {
      const node: any = i
      this.dataList.push({ id: node.id, name: node.name })
      if (node.children) {
        this.generateList(node.children)
      }
    }
  }
  public orgInputCheck() {
    if (this.userFromOrgMult.length > 0) {
      this.valORgHelp = ''
      this.valORgStatus = ''
    }  else {
      this.valORgHelp = this.$i18n.tc('user.add_modify_draw.error_org')
      this.valORgStatus = 'error'
    }
  }
  public async orgChangeFun(value, option) {
    const differentORGAdd = _.difference(value, this.orgChangeStore) // 返回添加的org为【】时表示没有添加，或删除
    const differentORGDee = _.difference(this.orgChangeStore, value) // 返回添加的org为【】时表示添加
    this.orgChangeStore = value
    // 绑定org时自动绑定设备
    if (differentORGAdd.length > 0) {
      await this.getORGDeviceBind(differentORGAdd, true, null)
    } else {
      await this.getORGDeviceBind(differentORGDee, false, option)
    }
  }
  public async getORGDeviceBind(orgIDString, addORDee, option) {
    // 绑定组织时自动绑定设备
    const orgID = _.split(orgIDString, ',', 2)[1]
    await getDeviceBindInfo(this.$store.state.main.userProfile.user_type, orgID)
    // 添加设备绑定
    if (addORDee) {
      const disabledArrayId: any = this.$store.state.main.deviceHardWare.deviceData.map((e) => {
        if (!_.includes(this.deviceBinds, e.id.toString())) {
          this.deviceBinds.push(e.id.toString())
        }
        return e.id
      })
      this.deviceDataCanBind = this.deviceDataCanBind.map((e) => {
        // 绑定了组织右侧置灰
        if (_.indexOf(disabledArrayId, e.id) > -1) {
          e.disabled = true
        }
        return e
      })
    } else {
      // 虽然移动到左侧，但是绑定了人员的项目
      const leftMoveButBind: any = []
      const deviceData = _.cloneDeep(this.$store.state.main.deviceHardWare.deviceData)
      // 如果在其他组织里也不移动
      let otherBind: any = []
      for (const e of option) {
        const orgIDExist = _.split(e.key, ',', 2)[1]
        await getDeviceBindInfo(this.$store.state.main.userProfile.user_type, orgIDExist)
        const tmp = await this.$store.state.main.deviceHardWare.deviceData.map((inner) => {
          return inner.id
        })
        otherBind = _.union(otherBind, tmp)
      }
      // 取得单独绑定人员的设备
      await getDeviceData_BindPerson(
        this.$store.state.main.userProfile.user_type,
        this.userdetailDataID)
      const deviceBindPerson = this.$store.state.main.userAuthorityShow.userData.devices.map((e) => {
        return e.id
      })
      // 删除设备绑定
      _.forEach(deviceData, (e) => {
        if (_.indexOf(otherBind, e.id) > -1) {
          return
        }
        // 设备单独绑定人员的话可以操作不移动
        if (_.indexOf(deviceBindPerson, e.id) > -1) {
          leftMoveButBind.push(e.id.toString())
          return
        }
        const getindex = _.indexOf(this.deviceBinds, e.id.toString())
        if (getindex > -1) {
          this.deviceBinds.splice(getindex, 1)
        }
      })
      this.deviceDataCanBind = this.deviceDataCanBind.map((e) => {
        if (_.indexOf(this.deviceBinds, e.id.toString()) === -1) {
          e.disabled = false
        }
        if ( _.indexOf(this.deviceHandbinds, e.id.toString()) > -1 ) {
          if ( this.deviceBinds.length - _.difference(this.deviceBinds, [e.id.toString()]).length === 1 ) {
            // 将手动选择的，且不在组织自动绑定的单独记录(记录只存在于devicebinds中只有一条的)
            e.disabled = false
          }
        }
        if (_.indexOf(leftMoveButBind, e.id.toString()) > -1) {
          e.disabled = false
        }
        return e
      })
    }
  }
  public getUserFromOrgMult() {
    let counter : number = 0
    // 将userFromOrgMult转化为取得带有id的ORG_IDS数组
    const orgTransResult : any  = this.userFromOrgMult.map((e) => {
      const orgTempData = _.split(e, ',', 2)
      if (!orgTempData[1]) {
        orgTempData[1] = this.userFormData.org_ids[counter].id
        counter += 1
      }
      return { name: orgTempData[0], id: orgTempData[1] }
    })
    counter = 0
    return orgTransResult
  }
  public nameInputCheck() {
    if (this.userFormData.name) {
      this.valNameHelp = ''
      this.valNameStatus = ''
    } else {
      this.valNameHelp = this.$i18n.tc('user.add_modify_draw.name_msg')
      this.valNameStatus = 'error'
    }
  }

  public mailInputCheck() {
    const regex = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z]+)+$/
    if (this.userFormData.email && !regex.test(this.userFormData.email)) {
        // 验证邮箱
        this.valEmailStatus = 'error'
        this.valEmailHelp = this.$i18n.tc('user.add_modify_draw.email_msg')
    } else {
        this.valEmailStatus = ''
        this.valEmailHelp = ''
    }
  }
  public async userDetailSubmit() {
    try {
      this.userFormData.org_ids = this.getUserFromOrgMult()
      commitSetLoading(this.$store, true)
      // 验证姓名
      this.nameInputCheck()
      // 验证组织
      this.orgInputCheck()
      // 验证邮箱
      this.mailInputCheck()
      // 验证电话号码
      this.telLengthCheck()
      // 验证图片
      const rtn = this.imageInputCheck()
      if (this.valNameStatus !== '' || this.valEmailStatus !== ''
      || this.valORgStatus !== '' || this.valTelStatus !== '' || !rtn) {
        commitSetLoading(this.$store, false)
        return
      }
      // 提交入口
      this.userFormData.org_ids = _.compact(
        this.userFormData.org_ids.map((e) => {
          return e.id
        })
      )
      // 设备绑定用人员信息
      let personData: any = {}
      // 新规的时候
      if (this.newFlag) {
        await creatNewPerson(this.$store.state.main.userProfile.user_type, this.userFormData)
        personData = this.$store.state.main.messagePro
      } else {
        await personInfoUpdate(this.theRanterIDs, this.userFormData)
        personData = this.userFormData
      }
      // 排除绑定组织的设备
      let otherBind: any = []
      for (const id of this.userFormData.org_ids) {
        await getDeviceBindInfo(this.$store.state.main.userProfile.user_type, id)
        const tmp = await this.$store.state.main.deviceHardWare.deviceData.map((inner) => {
          return inner.id.toString()
        })
        otherBind = _.union(otherBind, tmp)
      }
      const diffent = _.difference(this.deviceBinds, otherBind)
      // 绑定人员与设备
      await multiBindPersonDevices(
        personData,
        diffent,
        this.theRanterIDs
      )
      if (this.avatarFile.length > 0) {
        // 这里需要先提交头像，每次点击上传头像时，传入后台，取得后台的UUid与序号（序号为数组序号）
        await addPersonFaces(
          this.$store.state.main.userProfile.user_type,
          this.newFlag ? personData.id : this.userdetailDataID,
          this.avatarFile.map((e) => {
            return { image_url: e.person_face.image_url }
          })
        )
      }
      if (this.deleteDefaultAvatar.length > 0) {
        await multDeletePersonFace(this.deleteDefaultAvatar, this.newFlag ? personData.id : this.userdetailDataID)
      }
      // 更新人员信息刷新UserList
      await getAvatarUserData(this.$store.state.main.userProfile.user_type, this.orgData.id)
      pageReturnDatas.colseFlag = false
      this.$emit('userAddModifyDraw', pageReturnDatas)
      // 提交入口 END
      commitSetLoading(this.$store, false)
    } catch {
      return
    }
  }
  public userDetailClose() {
    this.$emit('userAddModifyDraw', pageReturnDatas)
  }
  public telLengthCheck() {
    const telRegx = /^[0-9\s-+]*$/
    if (this.userFormData.phone && !telRegx.test(this.userFormData.phone)) {
      this.valTelHelp = this.$i18n.tc('user.add_modify_draw.error_tel')
      this.valTelStatus = 'error'
    } else {
      this.valTelHelp = ''
      this.valTelStatus = ''
    }
  }
  public async created() {
    // 取得组织关系
    const tempOrgData = this.$store.state.main.enterprise.enterpriseData.map((e) => {
      if (this.theRanterIDs === e.id.toString()) {
        return e
      }
      return null
    })
    this.generateList(_.compact(tempOrgData))
    // 更新时查询人员信息
    if (this.newFlag) {
      this.userFormData = {name: '',
      org_ids: [{ id: this.orgData.id, name: this.orgData.name }],
      email: '',
      phone: '',
      personFaceId: ''}
      this.userFromOrgMult = [this.orgData.name + ',' + this.orgData.id]
      this.orgChangeStore = [this.orgData.name + ',' + this.orgData.id]
    } else {
      await getOnePersonDetail(this.userdetailDataID)
      this.userFormData = this.$store.state.main.userAuthorityShow.userData
      // 绑定选择的部门name
      this.userFromOrgMult = this.userFormData.org_ids.map((org: any) => {
        return org.name.toString() + ',' + org.id.toString()
      })
      // orgChange初始比对值(推算变化的org从而改变绑定的设备)
      this.orgChangeStore = this.userFormData.org_ids.map((org: any) => {
        return (org.name + ',' + org.id)
      })
      this.readonlyName = this.userFormData.name
    }
    await getTypeDeviceData(this.$store.state.main.userProfile.user_type, this.theRanterIDs)
    this.deviceDataCanBind = this.$store.state.main.deviceHardWare.deviceData.map((e) => {
      e.title = e.name
      e.key = e.id.toString()
      return e
    })
    // 新规时取得当前组织的绑定数据
    // 更新时重新查找db
    if (this.newFlag) {
      await this.getORGDeviceBind(this.orgData.name + ',' + this.orgData.id, true, null)
    } else {
      await getDeviceData_BindPerson(
        this.$store.state.main.userProfile.user_type,
        this.userdetailDataID)
      const bondDeviceData: any = this.$store.state.main.userAuthorityShow.userData
      this.deviceBinds = bondDeviceData.devices.map((e) => {
        return e.id.toString()
      })
      // 绑定组织的设备右侧置灰
      for (const e of this.userFromOrgMult) {
        await this.getORGDeviceBind(_.concat(e), true, null)
      }
      if (this.userFormData.person_faces.length  > 0) {
        for await (const iterator of this.userFormData.person_faces) {
          this.defaultAvatarHave += 1
          await getImageByPerson(this.theRanterIDs, iterator.image_url)
          const imgFile = {
            uid: this.defaultAvatarHave.toString(), // 不可重复
            name: this.defaultAvatarHave.toString() + '.png',
            status: 'done',
            url: await getBase64(this.$store.state.main.messagePro),
            personFaceId : iterator.id // 删除时，使用的接口需要传入的字段
          }
          this.fileList.push(imgFile)
        }
      }
    }
  }
}
