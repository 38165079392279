import './recognizeConfig'
import { getStorageUserType } from '@/utils/util'
import moment from 'moment'
import { requestToken } from '../../../api/manage'

/**
 * 获取折线图数据 按小时分
 * @param {String|Number} id 租户ID
 * @param {Object} config api接口可选项 org_ids | device_ids
 * @example <caption>返回示例</caption>
 * [
 *  {"hours": "1hours", "recognize_num": 10},
 *  {"hours": "2hours", "recognize_num": 49},
 * ...
 * ]
 * @returns {Promise}
 */
export function getRecognizeHistogram(id: number|string, config: RecognizeConfig) {
    config.time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone
    config.offset = moment().utcOffset()

    const url = `/api/v1/recognizes/${ getStorageUserType() }/recognizeHistogram/${ id }`

    return requestToken({
      url,
      method: 'get',
      params: config,
    })
}
