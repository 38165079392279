import './recognizeConfig'
import { getStorageUserType } from '@/utils/util'
import moment from 'moment'
import { requestToken } from '../../../api/manage'

/**
 * 获取认证数据
 * @param {String|Number} id 租户ID
 * @param {numner} config api接口可选项 org_ids device_ids
 * @example <caption>返回示例</caption>
 * {
 *  certified_num: 0
 *  female_num: 0
 *  male_num: 0
 *  recognize_num: 0
 *  temp_abnormal_num: 0
 *  temp_normal_num: 0
 *  total_num: 0
 *  uncertified_num: 0
 * }
 * @return {Promise}
 */
export function getRecognizeStatisticInfo(id: number|string, config: RecognizeConfig) {
  config.time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone
  config.offset = moment().utcOffset()

  const url = `/api/v1/recognizes/${getStorageUserType()}/recognizeStatisticInfo/${id}`

  return requestToken({
    url,
    method: 'get',
    params: config,
  })
}
