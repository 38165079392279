







import { Vue, Prop, Component } from 'vue-property-decorator'

@Component
export default class StatusItem extends Vue {
  @Prop({ required: true, default: '' }) public title!: string // 认证状态标题
  @Prop({ required: false, default: false }) public danger!: boolean // 是否显示危险颜色
}
