import { getStorageUserType } from '@/utils/util'
import moment from 'moment'
import { requestToken } from '../../../api/manage'
import './recognizeConfig'

/**
 * 返回最近认证的用户信息
 * @param orgId 租户ID
 * @param config api接口可选项 org_ids device_ids
 * @example
 * [
 *   {
 *     "name": "string",
 *     "person_type": "string",
 *     "person_number": "string",
 *     "gender": 0,
 *     "age": 0,
 *     "phone": "string",
 *     "email": "string",
 *     "certificate_type": "string",
 *     "certificate_number": "string",
 *     "created_by": "string",
 *     "created_date": "2021-07-30T06:42:28.636Z",
 *     "last_modified_by": "string",
 *     "last_modified_date": "2021-07-30T06:42:28.636Z",
 *     "id": 0,
 *     "org_ids": [
 *       "string"
 *     ],
 *     "person_faces": [
 *       {
 *         "image_url": "string",
 *         "id": 0
 *       }
 *     ],
 *     "last_seen_time": "string"
 *  }
 * ]
 * @return {Promise}
 */
export async function getRecognizePersonsInfo(orgId: number|string, config: RecognizeConfig) {
  const url: string = `/api/v1/recognizes/${ getStorageUserType() }/recognizePersonsInfo/${ orgId }`

  config.time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone
  config.offset = moment().utcOffset()

  return requestToken({
    url,
    method: 'get',
    params: config
  })
}
