






















































































































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator'

import svgUPPage from '@/components/svg_saves/home_page_show/UPpage.vue'
import svgDOWNPage from '@/components/svg_saves/home_page_show/DOWNPage.vue'
import svgEdit from '@/components/svg_saves/home_page_show/Edit.vue'
import svgSetting from '@/components/svg_saves/home_page_show/Setting.vue'
import svgSyncing from '@/components/svg_saves/home_page_show/Syncing.vue'
import svgDeviceBindUser from '@/components/svg_saves/home_page_show/user_list_home/DeviceBindUser.vue'
import svgCompany from '@/components/svg_saves/main/Company.vue'
import svgGroup from '@/components/svg_saves/home_page_show/org_pic/Group.vue'
import svgMore from '@/components/svg_saves/home_page_show/org_pic/More.vue'
import { getAvatarUserData } from '@/interfaces/service/person/userAuthority'
import { getTypeDeviceData } from '@/interfaces/service/device/deviceHardWare'
import { getRanterTreeData , getImageByORG, getOrgInfo, getFeatureTOrganizationCode } from '@/interfaces/service/org/enterpriseMang'
import mainSider from '../enterprise/GroupListHome.vue'
import mainUserListSider from '../user/UserListHome.vue'
import mainDeviceList from '../device/DeviceHomeList.vue'
import newRanterModel from '../enterprise/NewRanterModel.vue'
import globalList from './GlobalListRanter.vue'
import moment from 'moment'
import _ from 'lodash'
import { getBase64 } from '../../../interfaces/service/person/personMang'

interface OrgInfo {
  userNum: number,
  deviceNum: number
  suborgNum: number
}

@Component({
  components: {
    mainSider,
    mainUserListSider,
    mainDeviceList,
    svgUPPage,
    svgDOWNPage,
    svgEdit,
    svgSetting,
    svgSyncing,
    svgDeviceBindUser,
    svgCompany,
    newRanterModel,
    globalList,
  },

  data() {
    return {
      loading: true,
      mainSider,
      mainUserListSider,
      svgEdit,
      svgSetting,
      svgSyncing,
      svgCompany,
      svgDeviceBindUser,
      svgGroup,
      svgMore,
      newRanterModel,
    }
  },
  computed: {
    countCompany: {
      get() {
        /* 注意，这里的全局变量，在整个主页使用时，是不变动的，主页面中只用于储存所有的org树形结构数据，
         但另外2个变量，person（userAuthority）与device的的全局变量，因为子功能页面中的取值范围变化问题，
       全局变量只作为中间过渡值，取得异步中的response */
        return this.$store.state.main.enterprise.enterpriseData.length
      },
    },
  },
})
export default class HomePageShow extends Vue {
  public rantersData : any = []
  @Watch('$store.state.main.enterprise.enterpriseData') public ranterDataChange() {
     const ranterDatas =
       _.mapKeys(this.$store.state.main.enterprise.enterpriseData, (value, key) => {
        return value.id
      })
     this.rantersData = ranterDatas
  }
  get enterpriseHashData() {
    return this.$store.state.main.enterprise.enterpriseHashData
  }

  @Watch('$store.state.main.publicStatus.orgChangeData') public orgChangeDatas() {
    if (this.choseIndex > 0) {
      this.getAttributesNumByOrgId(this.choseIndex)
    }
  }

  public theWindowH = document.body.clientHeight
  public loading: boolean = true // page data onloading 页面进入骨架页面等待数据加载成功
  public userDatas: any = {}
  public deviceDatas: any = {}
  public countUsers: number = this.userDatas.lnegth
  public userInchildGroup: any = null
  public companyDrawFlag: boolean = false
  // company edit 企业编辑抽屉开关
  public enterpriseDetail: any = null
  // 左侧选中的子org
  public choseChildORG: any = null
  public orgId: number = -10
  public currentOrgAttr: any = {devices: 0 , persons: 0, sub_orgs: 0}
  public ranterId: number = -10
  public choseLength: number = 0
  public choseIndex: number = -1
  // 添加新的租户
  public addRanterModelFlag: boolean = false
  public closeAccess: boolean = true // 允许关闭租户选择
  // 取得组织内的设备
  public deviceCount: any = 0
  // 租户全局pullup菜单
  public ranterNavShow: boolean = false
  // 点击租户时，回到初始状态，不展开user
  public deviceShowFlag: boolean = false // 切换子组织数据与租户数据
  public breadcrumbMenuData: any[] = []
  public orgStatus: OrgInfo = {
    userNum: 0,
    deviceNum: 0,
    suborgNum: 0
  }
  public rootBreadcrumbNode: string = ''
  public breadcrumbData: any[] = []
  public isDone: boolean = false // 当前页面是否已完成加载
  public ranterAvatarUrl: any = ''
  @Watch('$store.state.main.screen.height') public windowHeight() {
    this.theWindowH = document.body.clientHeight
  }

  @Watch('selectedKeys')
  public selectedKeysUpdated() {
    this.getSubBreadcrumbData()
  }

  // 组织树形图被选中节点的Key
  public get selectedKeys() : any[] {
    let selectedKeys = this.$store.state.main.enterprise.selectedKeys

    if (_.isEmpty(selectedKeys)) {
      selectedKeys = JSON.parse(localStorage.getItem('selectedKeys') || '[]')
    }

    return selectedKeys
  }

  @Watch('$store.state.main.userAuthorityShow.userData')
  public updateUserData() {
    this.userInchildGroup = this.$store.state.main.userAuthorityShow.userData
  }

  @Watch('$store.state.main.deviceHardWare.deviceData')
  public updateDevicData() {
    this.deviceCount = this.$store.state.main.deviceHardWare.deviceData.length
  }

  /**
   * Tree Data 发生变化时
   */
  @Watch('rantersData')
  public updateRantersTreeData() {
    this.createHash(this.$store.state.main.enterprise.enterpriseData, {})
  }

  /**
   * 构建面包屑所需数据
   */
  public buildBreadcrumbData(selectedKey: any, queue: any[]): any[] {
    const enterpriseHashData = this.$store.state.main.enterprise.enterpriseHashData
    const selectedOrg = enterpriseHashData[selectedKey]

    if (_.isEmpty(selectedOrg)) {
      return []
    }

    const { parent_id } = selectedOrg

    if (parent_id === 0) {
      return queue
    }

    if (parent_id) {
      queue.unshift(selectedOrg)
      this.buildBreadcrumbData(parent_id, queue)
    } else {
      queue.unshift(selectedOrg)
    }

    // 判断层级是否大于2层
    if (queue.length > 2) {
      const breadcrumbData = [..._.take(queue, 1), '...', _.last(queue)]
      const menuData = _.slice( queue, 1 , queue.length - 1)
      this.breadcrumbMenuData = menuData
      return breadcrumbData
    }

    return queue
  }

  /**
   * 获取面包屑根节点
   */
  public async getRootBreadcrumbNode() {
    const enterpriseHashData: any = this.$store.state.main.enterprise.enterpriseHashData

    if (_.isEmpty(enterpriseHashData)) {
      this.rootBreadcrumbNode = ''
    }

    if (this.ranterId !== -10) {
      this.rootBreadcrumbNode = enterpriseHashData[this.ranterId].name
    }
    await getFeatureTOrganizationCode(this.ranterId)
  }

  /**
   * 获取面包屑子节点
   */
  public getSubBreadcrumbData() {
    // 从 store 中获取 选中的当前租户
    let selectedKeys = this.$store.state.main.enterprise.selectedKeys

    if (_.isEmpty(selectedKeys)) {
      selectedKeys = JSON.parse(localStorage.getItem('selectedKeys') || '[]')
    }

    this.breadcrumbData = this.buildBreadcrumbData(selectedKeys[0], [])
  }

  /**
   * 获取面包屑用数据
   */
  public getBreadcrumbNodes() {
    this.getRootBreadcrumbNode()
    this.getSubBreadcrumbData()
  }

  public companyDrawOn(ranterData) {
    this.companyDrawFlag = true
    this.enterpriseDetail = ranterData
  }
  public companyDrawOff() {
    this.companyDrawFlag = false
  }

  // 取得mainpage左侧sider中,选中的groupID =========
  public getSelectedEid(eid) {
    localStorage.setItem('childOrgChoseStore', JSON.stringify(eid))
    this.deviceShowFlag = true
    this.choseChildORG = eid.keys ? eid.keys : this.choseChildORG
    if (!this.choseChildORG) {
      return
    }
    this.choseIndex = eid.pageIndex
    if (eid.keys) {
      this.userInchildGroup = this.$store.state.main.userAuthorityShow.userData
      this.orgId = eid.keys.id
    } else {
      this.userInchildGroup = null
    }
    this.choseLength = this.choseChildORG.children ? this.choseChildORG.children.length : 0
    this.getBreadcrumbNodes()
    this.getAttributesNumByOrgId(this.orgId)
  }
  // userData获取
  public async userData(orgid) {
    // 遍历组织中的人员
    this.userDatas = []
    {
      // 真实数据
      await getAvatarUserData(this.$store.state.main.userProfile.user_type, orgid)
      this.userDatas = this.$store.state.main.userAuthorityShow.userData
    }
  }
  public async deviceData(orgid) {
    {
      await getTypeDeviceData(this.$store.state.main.userProfile.user_type, orgid)
      this.deviceDatas = this.$store.state.main.deviceHardWare.deviceData
    }
  }

  public tableViewEntry(tableData) {
    sessionStorage.setItem('ranterId', tableData.id)
    this.$store.commit('setRanterId', tableData.id)
    sessionStorage.setItem('ranterNameChose', tableData.name)
    this.$router.push({
      path: `/main/guider-nav-page/tables/person`,
      query: {
        ranterName: tableData.name,
      },
    })
  }

  // 时间显示格式
  public timeShowFormat(time) {
    if (time) {
      return moment(time).format('YYYY.MM.DD')
    } else {
      return '-'
    }
  }
  public async getDeviceInGroup(orgID) {
    await getTypeDeviceData(this.$store.state.main.userProfile.user_type, orgID)
    this.deviceCount = this.$store.state.main.deviceHardWare.deviceData.length
  }
  public ranterListShow() {
    this.ranterNavShow = !this.ranterNavShow
  }

  public navChanger(returnValue) {
    if (returnValue.addRanter === 'show') {
      this.addRanterModelFlag = true
      this.closeAccess = false
    }
    if (returnValue.ranterNavShow === 'off') {
      this.ranterNavShow = false // 关闭显示
    }
    if (returnValue.index !== -1) {
      // 切换租户
      const index = returnValue.index - 1
      this.ranterId = returnValue.ranterID
      this.orgId = this.ranterId
      this.loading = true
      // 获取最新TreeData
      // 重新获取租户头像
      if (returnValue.ranterData.avatar) {
        this.getRanterAvatar(this.ranterId, returnValue.ranterData.avatar)
      }
      // 重新取得租户设备和用户信息
      this.userData(this.ranterId)
      this.deviceData(this.ranterId)

      // this.getRanterAvatar(this.ranterId, )
      sessionStorage.setItem('ranterId', returnValue.ranterID)
      this.$store.commit('setRanterId', this.ranterId)
      this.$store.commit('setSelectedKeys', [])
      localStorage.setItem('selectedKeys', '[]')
      localStorage.setItem('childOrgChoseStore', '')
      this.$store.commit('setExpandedKeys', [])
      sessionStorage.setItem('expandedKeys', '[]')
      this.choseIndex = -1
      this.getBreadcrumbNodes()
      setTimeout(() => {
        this.loading = false
      }, 500)
    }
  }
  public newRanterClose() {
    this.addRanterModelFlag = false
    setTimeout(() => {
      this.closeAccess = true
    }, 300)
  }
  public ranterCardHome() {
    this.choseIndex = -1
    this.deviceShowFlag = false
    // 去掉组织树状结构选中
    this.$store.commit('setSelectedKeys', [])
    localStorage.setItem('selectedKeys', JSON.stringify([]))
  }

  /**
   * 获取组织的基本统计信息（设备、人员、子组织数量）
   */
  public async getAttributesNumByOrgId(orgId) {
    const res: any =  await getOrgInfo(orgId)
    if (res) {
      this.currentOrgAttr = await getOrgInfo(orgId)
    }
  }
  // 取得mainpage中右选中的groupID END =========

  /**
   * 面包屑跳转
   */
  public handleBreadcrumbLink(e, selectedOrg) {
    if (!_.isObject(selectedOrg)) {
      e.returnValue = true
      e.preventDefault()
      return
    }
    this.$store.commit('setSelectedKeys', [(selectedOrg as any).id])
    this.$store.commit('setSelectedOrg', selectedOrg)
    localStorage.setItem('selectedKeys', JSON.stringify([(selectedOrg as any).id]))
    this.deviceShowFlag = true
    this.choseChildORG = selectedOrg
    this.userInchildGroup = null

    this.userInchildGroup = this.$store.state.main.userAuthorityShow.userData
    this.getSubBreadcrumbData()
  }

  /**
   * 面包屑根节点跳转
   */
  public handleRootClick() {
    this.ranterCardHome()
    this.$store.commit('setSelectedOrg', [])
    localStorage.setItem('childOrgChoseStore', '')
    this.orgId = this.ranterId
    this.choseLength = 0
    this.getSubBreadcrumbData()
  }

  public handleUserDataChange(data) {
    this.orgStatus.userNum = data.size
  }

  public handleDeviceDataChange(data) {
    this.orgStatus.deviceNum = data.size
  }


  public handleMainSiderChange() {
    this.handleRootClick()
  }

  /**
   * 构建树状结构hashMap
   */
  private createHash(tree, hash) {
    if (_.isEmpty(tree)) {
      this.$store.commit('setEnterpriseHashData', {})
      return {}
    }

    _.forEach(tree, (item: any, index: number) => {
      let maxSort = 0

      _.forEach(tree, ( iteratee ) => {
        if (iteratee.sort > maxSort) {
          maxSort = iteratee.sort
        }
      })

      if (_.has(item, 'children')) {
        // 前一个节点 id
        if (index - 1 >= 0) {
          item.prev_id = tree[index - 1].id
        }
        // 后一个节点 id
        if (index + 1 < tree.length) {
          item.next_id = tree[index + 1].id
        }

        item.max_sort = maxSort
        hash[item.id] = item
        this.createHash(item.children, hash)
      } else {
        // 前一个节点 id
        if (index - 1 >= 0) {
          item.prev_id = tree[index - 1].id
        }
        // 后一个节点 id
        if (index + 1 < tree.length) {
          item.next_id = tree[index + 1].id
        }
        item.max_sort = maxSort
        hash[item.id] = item
      }
    })

    this.$store.commit('setEnterpriseHashData', hash)
    sessionStorage.setItem('getEnterpriseHashData', JSON.stringify(hash))
    return hash
  }

  /**
   * 获取租户头像
   * @param {number} ranterId 租户id
   * @param {url} string 该租户的头像url地址
   */
  private async getRanterAvatar(ranterId, url) {
    if (!url) {
      this.ranterAvatarUrl = ''
      return
    }

    await getImageByORG(ranterId, url)
    this.ranterAvatarUrl = await getBase64(this.$store.state.main.messagePro)
  }

  public async created() {
    await getRanterTreeData(this.$store.state.main.userProfile.user_type)
    if (this.$store.state.main.enterprise.enterpriseData.length < 1) {
      // 没有租户时，进入租户的创建
      this.$router.replace('/home-header/home-blank')
      } else {
        this.rantersData =
        _.mapKeys(this.$store.state.main.enterprise.enterpriseData, (value, key) => {
          return value.id
        })
        const { enterpriseData } = this.$store.state.main.enterprise
        // 缓存中是否已经存在 ranterId
        const storageRanterId: number = +(sessionStorage.getItem('ranterId') || -10)
        if (storageRanterId !== -10) {
          this.ranterId = storageRanterId
        } else {
          this.ranterId = enterpriseData[0] ? enterpriseData[0].id : -10
          sessionStorage.setItem('ranterNameChose',  enterpriseData[0].name) // 租户名称
        }
        if (!this.rantersData[this.ranterId]) {
          // 找不到租户时给一个默认的租户id
          this.ranterId = enterpriseData[0] ? enterpriseData[0].id : -10
        }
        this.orgId = this.selectedKeys[0]
        // 若无选中组织， 组织ID = 租户ID
        if (!this.orgId) {
          this.orgId = this.ranterId
        }

        // 若选中的组织已经被删除
        if (!this.enterpriseHashData[this.orgId]) {
          // 重置前画面组织 tree 选中状态
          this.$store.commit('setSelectedKeys', [])
          localStorage.setItem('selectedKeys', '[]')
          // 重置前画面选中子组织状态
          localStorage.setItem('childOrgChoseStore', '')
          this.orgId = this.ranterId
        }

        sessionStorage.setItem('ranterId', this.ranterId.toString())
        this.$store.commit('setRanterId', this.ranterId)
        this.userData(this.ranterId)
        this.deviceData(this.ranterId)
        this.loading = !this.loading
        const defaultOrgFreshStore = JSON.stringify({key: null, pageIndex: -1})
        const childOrgChose: any = JSON.parse(localStorage.getItem('childOrgChoseStore') || defaultOrgFreshStore)

        if (localStorage.getItem('childOrgChoseStore') !== '') {
          this.getSelectedEid(childOrgChose)
        }
        // 获取当前租户的头像
        if (this.rantersData[this.ranterId].avatar) {
          this.getRanterAvatar(this.ranterId, this.rantersData[this.ranterId].avatar)
        }
        this.getBreadcrumbNodes()
        this.isDone = true
      }
  }
}
