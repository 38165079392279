









































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import _ from 'lodash'
import searchTool from '../other/SearchToolBar.vue'
import newORGModel from '../enterprise/NewAddModel.vue'
import { deleteAll, getRanterTreeData, getDirectlyAttrNum, delOrg } from '../../../interfaces/service/org/enterpriseMang'
import { updateOrg } from '../../../interfaces/service/admin/enterpriseDetail'
import svgUserIdCard from '../../../components/svg_saves/home_page_show/person_add/UserIdCard.vue'
import svgXCross from '../../../components/svg_saves/home_page_show/person_add/XCross.vue'
import Menu from '../../../components/Menu'
import { flatten } from '../../../utils/util'
import {commitSetOrgChangeData } from '../../../store/main/mutations'

type MoveType = 'up' | 'down'

@Component({
  components: {
    searchTool,
    svgUserIdCard,
    svgXCross,
    newORGModel,
    Menu
  },
  data() {
    return {
      replaceFields: {
        key: 'id',
        children: 'children',
        title: 'name',
      },
      svgUserIdCard,
      svgXCross
    }
  },
})
export default class GroupListHome extends Vue {
  @Watch('ranterData')
  public getEnterpriseData() {
    if (_.isEmpty(this.ranterData) || _.isEmpty(this.ranterData.children)) {
      this.enterpriseData = []
      return
    }
    this.enterpriseData = this.recursionTree(this.ranterData.children)
  }
  public enterpriseData : any = {}
  // 组织树形图被选中节点的Key
  public get selectedKeys() : any[] {
    let selectedKeys = this.$store.state.main.enterprise.selectedKeys
    if (_.isEmpty(selectedKeys)) {
      selectedKeys = JSON.parse(localStorage.getItem('selectedKeys') || '[]')
    }
    return selectedKeys
  }

  public get expandedKeys() : any[] {
    let expandedKeys = this.$store.state.main.enterprise.expandedKeys
    if (_.isEmpty(expandedKeys)) {
      expandedKeys = JSON.parse(sessionStorage.getItem('expandedKeys') || '[]')
    }
    return expandedKeys
  }

  @Prop({ required: true }) public ranterData!: any
  // public expandedKeys: any = []
  public searchValue: string = ''
  public autoExpandParent: boolean = true
  // public mainSiderLineShowFlag: boolean = true
  public groupListVisible: boolean = false // Add button action flag 点击加号按钮反馈
  // Search treeData function 搜索树方法
  public dataList: any = []
  public loading: boolean = true
  public selectedOrgId: number = -10 // 选中的组织 ID
  public hoverOrgId: number = -10
  public selectedOrg: any = [] // 选中的组织
  public menuVisible: boolean = false // 是否显示菜单
  public offsetX: string = '' // 菜单 left 位置
  public offsetY: string = '' // 菜单 top 位置
  public deleteModalVisible: boolean = false // 删除组织 modal 提示框
  public addModalData: any[] = []
  public modalFlag: string = 'insert'  // update | insert
  public orgEditInfo: any = {}
  public hoverOrgInfo: any = {}
  public hasPrev: boolean = true // 当前hover的组织有没有前节点
  public hasNext: boolean = true // 当前hover的组织有没有后节点
  public deleteEmptyOrg: boolean = true
  public deleteOrgOrChange: number = 0
  public delOrgInfo: any = {}
  public orgList: any = []
  public delOrgMoveID: any = 0
  public showOrgCode: boolean = this.$store.state.main.enterprise.showOrgCode
  @Watch('$store.state.main.enterprise.showOrgCode') public watchShowOrgCode() {
    this.showOrgCode = this.$store.state.main.enterprise.showOrgCode
  }
  @Watch('hoverOrgId')
  public changeBackcolor() {
    this.$nextTick(() => {
      // backclor change
      const tree: Vue = this.$refs.tree as Vue
      const wrapper = tree.$el.getElementsByClassName('ant-tree-node-content-wrapper')
      _.forEach(wrapper, (item) => {
        const attr = item.getAttribute('class') as string
        const child: Element = item.firstElementChild?.firstElementChild?.firstElementChild as Element
        if (child.hasAttribute('class') &&
        (child.getAttribute('class') as string).indexOf('item__title__cmdshow') >= 0) {
          item.setAttribute('class', attr.indexOf('cmdshow') >= 0 ? attr : attr + ' cmdshow')
        } else {
          item.setAttribute('class', attr.indexOf('cmdshow') >= 0 ? attr.replace(' cmdshow', '') : attr)
        }
      })
    })
  }
  public searchAction(searchData) {
    if (searchData) {
      this.groupListVisible = searchData.plusFlag
      switch (searchData.searchType) {
        case 'childGroup':
          if (searchData.searchValue) {
             this.onSearch(searchData.searchValue)
          }
          // this.mainSiderLineShowFlag = searchData.lineShowFlag
          this.modalFlag = 'insert'
          break
      }
    }
  }

/**
 * 递归遍历
 */
public recursionTree(tree: any) {
  if (_.isEmpty(tree)) {
    return
  }
  _.forEach(tree, (item : any) => {
    item.key = item.id
    item.value = item.id
    if (_.has(item, 'children')) {
      item.scopedSlots =  { title: 'custom' }
      this.recursionTree(item.children)
    } else {
      item.scopedSlots =  { title: 'custom' }
    }
  })
  return tree
}

public expandChange(expandedKeys, expandNode) {
  // this.expandedKeys = expandedKeys
  this.$store.commit('setExpandedKeys', expandedKeys)
  sessionStorage.setItem('expandedKeys', JSON.stringify(expandedKeys))
  this.autoExpandParent = false
}

// search Tree Data action,搜索树方法触发
public onSearch(e) {
  this.generateList(this.enterpriseData)
  const value = e
  const expandedKeys = this.dataList
    .map((item: any) => {
      if (item.title.indexOf(value) > -1) {
        return this.getParentKey(item.key, this.enterpriseData)
      }
      return null
    })
    .filter((item, i, self) => item && self.indexOf(item) === i)

  this.$store.commit('setExpandedKeys', expandedKeys)
  Object.assign(this, {
    searchValue: value,
    autoExpandParent: true,
  })
}

  // public mainSiderLineShowFlagChange() {
  //   this.mainSiderLineShowFlag = !this.mainSiderLineShowFlag
  // }
  public onSelect(selectedKeys, info) {
    // 不能取消选择
    if (!info.selected) {
      info.nativeEvent.returnValue = true
      info.nativeEvent.preventDefault()
      return
    }
    localStorage.setItem('selectedKeys', JSON.stringify(selectedKeys))
    this.$store.commit('setSelectedKeys', selectedKeys)
    // 处于选中状态时
    if (!_.isEmpty(info.selectedNodes)) {
      this.selectedOrgId = selectedKeys[0]
      this.selectedOrg = info.selectedNodes[0].data.props.dataRef
      // 存入 store 中
      this.$store.commit('setSelectedOrg', this.selectedOrg)
    }
    // selectedKeys[0] == info.selectedNodes[0].data.props.id 此处取id到付父组件，这俩是同一个值
    const outProps = {
      // keys: info.selectedNodes[0] ? info.selectedNodes[0].data.props : null,
      keys: info.selectedNodes[0] ? this.selectedOrg : null,
      pageIndex: info.selectedNodes[0] ? this.selectedOrg.id : -1,
    }
    this.$emit('selectedEid', outProps)
  }
  public generateList = (data) => {
    for (const i of data) {
      const node: any = i
      const key: number = node.id
      this.dataList.push({ key, title: node.name })
      if (node.children) {
        this.generateList(node.children)
      }
    }
  }
  public getParentKey = (key, tree) => {
    let parentKey
    for (const i of tree) {
      const node = i
      if (node.children) {
        if (node.children.some((item) => item.id === key)) {
          parentKey = node.id
        } else if (this.getParentKey(key, node.children)) {
          parentKey = this.getParentKey(key, node.children)
        }
      }
    }
    return parentKey
  }
  // Search treeData function 搜索树方法
  // model show
  public newOrgModel(obj) {
    this.groupListVisible = obj.visable
  }

  /**
   * 打开Menu
   */
  public handleOpenMenu(e, item) {
    // 是否显示 上移，下移
    this.hoverOrgId = item.id
    this.selectedOrg = item
    const hoverOrgInfo =   this.getHoverOrgInfo(this.hoverOrgId)

    const { prevOrg , nextOrg } = hoverOrgInfo
    this.hasPrev = !_.isEmpty(prevOrg)
    this.hasNext = !_.isEmpty(nextOrg)

    // Menu 坐标位置计算
    const viewportY = document.body.clientHeight // 浏览器当前视窗高度
    const menuWidth = 140 // 整个菜单栏宽度
    let menuHight = 163 // 整个菜单栏默认高度
    const margin = 6 // 距离点击 ico n的 margin 值
    let offsetX = 0
    let offsetY = 0

    if (!this.hasPrev) {
      menuHight -= 32
    }

    if (!this.hasNext) {
      menuHight -= 32
    }

    this.menuVisible = true
    const rect = e.target.getBoundingClientRect() || {} // 获取点击的dom元素信息
    const { height, width, x, y } = rect
    const deviation = height / 2 // 点击范围误差值
    offsetY = height + y + margin
    offsetX = x - menuWidth + width

    // 计算菜单是否超出屏幕
    if (offsetY + menuHight + margin + deviation > viewportY) {
      offsetY = offsetY - 48 - menuHight - margin
    }

    this.offsetX = offsetX + 'px'
    this.offsetY = offsetY + 'px'
  }

  /**
   * 处理关闭菜单
   */
  public handleMenuClose() {
    // this.hoverOrgId = -10
    this.menuVisible = false
  }

  /**
   * 编辑菜单
   */
  public handleEdit() {
    this.groupListVisible = true
    this.menuVisible = false
    this.modalFlag = 'update'
    this.orgEditInfo = this.selectedOrg
  }

  /**
   * 上移
   */
  public handleMoveUp() {
    this.handleMove('up')
  }

  /**
   * 下移
   */
  public handleMoveDown() {
    this.handleMove('down')
  }

  /**
   * 新建组织
   */
  public handleCreate() {
    this.groupListVisible = true
    this.menuVisible = false
    this.modalFlag = 'insert'
    this.orgEditInfo = {name: '', parent_id: this.selectedOrg.id}
  }

  /**
   * 删除
   */
  public handleDelete() {
    this.menuVisible = false
    // 查询出当前删除的组织 下的情况
    const deleteId = this.hoverOrgId
    getDirectlyAttrNum(deleteId)
    .then( (res: any) => {
      this.delOrgInfo = res
      this.deleteEmptyOrg = (res.sub_orgs + res.persons + res.devices) === 0
      this.deleteModalVisible = true
      this.delOrgMoveID = deleteId
      this.orgList = []
      this.generateOrgList(this.$store.state.main.enterprise.enterpriseData, deleteId)
    }).catch(() => {
        this.deleteModalVisible = false
    })
  }

  public deleteModalOk() {
    const enterpriseHashData: any = this.$store.state.main.enterprise.enterpriseHashData
    const deleteId = this.hoverOrgId
    console.log(deleteId)
    const moveID = this.deleteOrgOrChange === 0 ? 0 : this.delOrgMoveID
    delOrg(deleteId, moveID).then( () => {
      // 删除的当前选中的组织
      if (deleteId === this.selectedOrgId) {
        this.$emit('onChange')
      }
      this.$message.success(
        `${this.$i18n.tc('delete')} ${ enterpriseHashData[deleteId].name } ${this.$i18n.tc('success')}`
        )
      this.deleteModalVisible = false
      // 删除成功，重新获取 Tree 数据
      getRanterTreeData(this.$store.state.main.userProfile.user_type)
      commitSetOrgChangeData(this.$store, Number(new Date()))
    }).catch(() => {
        this.$message.error(
          `${this.$i18n.tc('delete')} ${ enterpriseHashData[deleteId].name } ${this.$i18n.tc('failed')}`
          )
        this.deleteModalVisible = false
      })
  }

  /**
   * deleteModalOk
   */
  public deleteModalOk1() {
    const enterpriseHashData: any = this.$store.state.main.enterprise.enterpriseHashData
    const flattened = flatten(enterpriseHashData[this.hoverOrgId].children)
    const fattenedId = _.map(flattened, (item) => {
      return item.id
    })
    const deleteId = this.hoverOrgId
    deleteAll(this.$store.state.main.userProfile.user_type, [this.hoverOrgId, ...fattenedId])
    .then( () => {
      // 删除的当前选中的组织
      if (deleteId === this.selectedOrgId) {
        this.$emit('onChange')
      }
      this.$message.success(
        `${this.$i18n.tc('delete')} ${ enterpriseHashData[deleteId].name } ${this.$i18n.tc('success')}`
        )
      this.deleteModalVisible = false
      // 删除成功，重新获取 Tree 数据
      getRanterTreeData(this.$store.state.main.userProfile.user_type)
      commitSetOrgChangeData(this.$store, Number(new Date()))
    }).catch(() => {
        this.$message.error(
          `${this.$i18n.tc('delete')} ${ enterpriseHashData[deleteId].name } ${this.$i18n.tc('failed')}`
          )
        this.deleteModalVisible = false
      })
  }

  /**
   * deleteModalCancle
   */
  public deleteModalCancle() {
    this.deleteModalVisible = false
  }

  /**
   * 获取处于 hover 状态下选中的组织信息
   */
  private getHoverOrgInfo(hoverOrgId) {
    const enterpriseHashData: any = this.$store.state.main.enterprise.enterpriseHashData || []
    const { id, prev_id, next_id } = enterpriseHashData[hoverOrgId] || {}
    const currentOrg = enterpriseHashData[id] || {}
    const prevOrg = enterpriseHashData[prev_id] || {}
    const nextOrg = enterpriseHashData[next_id] || {}

    return {
      currentOrg,
      prevOrg,
      nextOrg
    }
  }

  /**
   * 处理菜单上移下移
   * @param {MoveType} type 移动类型 up | down
   */
  private handleMove(type: MoveType) {
    const hoverOrgInfo = this.getHoverOrgInfo(this.hoverOrgId)
    const { currentOrg, prevOrg, nextOrg } = hoverOrgInfo

    let sort: number = 0
    let p1: any = null
    let p2: any = null
    let p3: any = null

    // 上移 -- 更新前一个组织
    if (type === 'up') {
      sort = prevOrg.sort
      p2 = updateOrg(prevOrg.id, {
        parent_id: prevOrg.parent_id,
        sort: currentOrg.sort
      })
    }

    // 下移 -- 更新后一个组织
    if (type === 'down') {
      sort = nextOrg.sort
      p1 = updateOrg(nextOrg.id, {
        parent_id: nextOrg.parent_id,
        sort: currentOrg.sort
      })
    }

    // 更新当前组织
    p3 = updateOrg(currentOrg.id, {
      parent_id: currentOrg.parent_id,
      sort
    })

    Promise.all([p1, p2, p3]).then(() => {
    // 下移操作完成，重新获取treeData`
    getRanterTreeData(this.$store.state.main.userProfile.user_type).then((res) => {
      const newHoverOrgInfo = this.getHoverOrgInfo(this.hoverOrgId)
      // 是否显示上移，下移菜单项
      this.hasPrev = !_.isEmpty(newHoverOrgInfo.prevOrg)
      this.hasNext = !_.isEmpty(newHoverOrgInfo.nextOrg)
    })
  })
  }

  private created() {
    setTimeout(() => {
      this.loading = false
    }, 500)
  }
  public generateOrgList(data, delId) {
    for (const i of data) {
      const node: any = i
      if (node.ancestors.split(',').indexOf(delId + '') === -1) {
        this.orgList.push({ id: node.id, name: node.name })
      }
      if (node.children) {
        this.generateOrgList(node.children, delId)
      }
    }
  }
}
