























































import { Vue, Component, Watch } from 'vue-property-decorator'
import _ from 'lodash'
import { getBase64 } from '../../../interfaces/service/person/personMang'
import { commitSetLoading } from '../../../store/main/mutations'
import {
  addNewGroup,
  getRanterTreeData,
  uploadImage,
} from '../../../interfaces/service/org/enterpriseMang'
@Component({})
export default class HomeBlank extends Vue {
  public theEname: string = ''
  public userDescripton: string = ''
  // 头像上传
  public previewVisible: boolean = false
  public previewImage: string = ''
  public fileList: any = [] // 头像文件数组
  public avatarFile: string = '' // 上传头像后用于绑定user
  public subLoading: boolean = false
  public fileStatus: boolean = false
  public noRoot : boolean = true
  public theWindowW = document.body.clientWidth
  public theWindowH = document.body.clientHeight
  @Watch('$store.state.main.screen.width') public windowWidth() {
    this.theWindowW = document.body.clientWidth
  }
  @Watch('$store.state.main.screen.height') public windowHeight() {
    this.theWindowH = document.body.clientHeight
  }
  @Watch('$store.state.main.waitloading')
  public resetLoading() {
    this.subLoading = this.$store.state.main.waitloading
  }
  public async modelSubmit() {
    try {
      if (this.theEname) {
        commitSetLoading(this.$store, true)
        await addNewGroup(this.$store.state.main.userProfile.user_type, {
          name: this.theEname,
          avatar: this.avatarFile,
          description: this.userDescripton,
        })
        await getRanterTreeData(this.$store.state.main.userProfile.user_type)
        commitSetLoading(this.$store, false)
        this.$router.replace('/home-header/home')
      } else {
        commitSetLoading(this.$store, false)
        this.$message.error(this.$i18n.tc('enterprise.new_enterprise.name_msg'))
        return
      }
    } catch {
      return
    }
  }
  public async avatarPreview(file) {
    // 头像预览
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    this.previewImage = file.url || file.preview
    this.previewVisible = true
  }
  public beforeUpload(file) {
    // 文件check
    this.imgUpLoadCheck(file)
    return false
  }

  public imgUpLoadCheck(file) {
    if (file.type === 'image/png' || file.type === 'image/jpeg') {
     if (file.size > 2048000) {
        // 大于2MB
        this.$message.error(this.$i18n.tc('enterprise.new_enterprise.error_more_than_2m'))
        this.fileStatus = false
      }
    } else {
      // 非一般图片格式
      this.$message.error(this.$i18n.tc('enterprise.new_enterprise.error_jpg_only'))
      this.fileStatus = false
    }
    this.fileStatus = true
  }

  public async avatarChange(fileList) {
    // 只有新加图片并且检证OK的时候才上传
    if (this.fileStatus) {
      this.fileStatus = false
      // 每次点击上传头像时，传入后台，取得后台的Uid与序号（序号为数组序号）
      await uploadImage({
        // 上传头像
        upload_file: fileList.file,
        upload_seq: this.fileList.length + 1,
        org_id: 0, // TODO默认是根组织0，进入temp文件夹
      })
      const imageInfo = this.$store.state.main.messagePro
      this.avatarFile = imageInfo.avatar
      this.fileList = fileList.fileList
    }
  }
  // 删掉上传的图片
  public handleRemove(file) {
    const index = this.fileList.indexOf(file)
    const newFileList = this.fileList.slice()
    newFileList.splice(index, 1)
    this.fileList = newFileList
    this.avatarFile = '' // 删除用于绑定的图片url
  }

  public avatarCancel() {
    // 关闭预览
    this.previewVisible = false
  }
}
