















































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import searchTool from '../other/SearchToolBar.vue'
import _ from 'lodash'
import { multDeviceBind } from '../../../interfaces/service/org/enterpriseMang'
import { getTypeDeviceData } from '../../../interfaces/service/device/deviceHardWare'
import { commitSetLoading, commitSetOrgChangeData } from '../../../store/main/mutations'
const returnData: any = { visable: true }
@Component({
  components: {
      searchTool
    },
  data() {
    return {
    }
  },
})
export default class NewDeviceModel extends Vue {
  public deviceDatas: any = []
  @Prop({ required: true }) public groupOrgID!: any
  // 添加员工
  public deviceBinds: any = [] // 选中的userID用于绑定
  public theWindowH = document.body.clientHeight // 窗口高度

  public addDeviceFlag: boolean = false
  public lineShowSwitch: boolean = true
  public subLoading: boolean = false
  @Watch('$store.state.main.waitloading')
  public resetLoading() {
    this.subLoading = this.$store.state.main.waitloading
  }
  @Watch('$store.state.main.screen.height') public windowHeight() {
    this.theWindowH = document.body.clientHeight
  }
  public transChange(targetKeys, direction, moveKeys) {
    this.deviceBinds = targetKeys
  }
  public modelCancel() {
    // model取消
    this.addDeviceFlag = false
    returnData.visable = false
    returnData.userDataBind = null
    this.$emit('modelFun', returnData)
  }
  public async modelSubmit() {
    commitSetLoading(this.$store, true)
    // model提交
    this.addDeviceFlag = false
    returnData.visable = false
    await multDeviceBind(
      this.$store.state.main.userProfile.user_type,
      this.groupOrgID,
      this.deviceBinds
    )
    await getTypeDeviceData(
      this.$store.state.main.userProfile.user_type,
      this.groupOrgID
    )
    this.$emit('modelFun', returnData)
    commitSetLoading(this.$store, false)
    commitSetOrgChangeData(this.$store, Number(new Date()))
  }

  private async created() {
    const ranterId = sessionStorage.getItem('ranterId')
    await getTypeDeviceData(this.$store.state.main.userProfile.user_type, ranterId)
    this.deviceDatas = this.$store.state.main.deviceHardWare.deviceData.map(
        (e) => {
          e.title = e.name
          e.key = e.id.toString()
          return e
        }
      )
    await getTypeDeviceData(this.$store.state.main.userProfile.user_type, this.groupOrgID)
    this.deviceBinds = this.$store.state.main.deviceHardWare.deviceData.map(
        (e) => {
          return e.id.toString()
        }
      )
  }
}
